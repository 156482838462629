import { Routes } from '@angular/router';

import { loginPagesResolver, roomLoginInfoResolver } from './core';
import {
  EMAIL_CODE_PATH,
  EXTERNAL_IDP_PATH,
  FORGOT_PASSWORD_PATH,
  INCOMPATIBLE_BROWSER_PATH,
  INVITE_PATH,
  LEGAL_AGREEMENTS_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  REGISTRATION_PATH,
  RESET_PASSWORD_INIT_PATH,
  RESET_PASSWORD_PATH,
  RESET_USER_PATH,
  SELECT_ROLE_PATH,
  SIGNIN_ERROR_PATH,
  TWO_FA_PATH,
} from './types';

import { appGuard } from './app.guard';

export const appRoutes: Routes = [
  {
    path: INCOMPATIBLE_BROWSER_PATH,
    loadChildren: () => import('./incompatible-browser/incompatible-browser.module').then((m) => m.IncompatibleBrowserModule),
  },
  {
    path: '',
    canActivate: [appGuard],
    children: [
      {
        path: '',
        redirectTo: LOGIN_PATH,
        pathMatch: 'full',
      },
      {
        path: REGISTRATION_PATH,
        loadChildren: () => import('./registration/local-idp/local-registration.routes').then((m) => m.localRegistrationRoutes),
      },
      {
        path: EXTERNAL_IDP_PATH,
        loadChildren: () => import('./registration/external-idp/external-registration.routes').then((m) => m.externalRegistrationRoutes),
      },
      {
        path: LOGIN_PATH,
        resolve: { loginPage: loginPagesResolver },
        loadChildren: () => import('./login/login.routes').then((m) => m.loginRoutes),
      },
      {
        path: TWO_FA_PATH,
        loadChildren: () => import('./2fa/two-fa.routes').then((m) => m.twoFaRoutes),
      },
      {
        path: SIGNIN_ERROR_PATH,
        loadChildren: () => import('./signin-error/signin-error.routes').then((m) => m.signinErrorRoutes),
      },
      {
        path: FORGOT_PASSWORD_PATH,
        resolve: { loginPage: loginPagesResolver, roomLoginInfo: roomLoginInfoResolver },
        loadChildren: () => import('./forgot/forgot.routes').then((m) => m.forgotRoutes),
      },
      {
        path: RESET_PASSWORD_INIT_PATH,
        loadChildren: () => import('./reset-password-init/reset-password-init.routes').then((m) => m.resetPasswordInitRoutes),
      },
      {
        path: RESET_PASSWORD_PATH,
        loadChildren: () => import('./reset-password/reset-password.routes').then((m) => m.resetPasswordRoutes),
      },
      {
        path: RESET_USER_PATH,
        loadChildren: () => import('./reset-user/reset-user.routes').then((m) => m.resetUserRoutes),
      },
      {
        path: INVITE_PATH,
        loadChildren: () => import('./invite/invite.routes').then((m) => m.inviteRoutes),
      },
      {
        path: LEGAL_AGREEMENTS_PATH,
        loadChildren: () => import('./legal-agreements/legal-agreements.routes').then((m) => m.legalAgreementsRoutes),
      },
      {
        path: LOGOUT_PATH,
        loadChildren: () => import('./logout/logout.routes').then((m) => m.logoutRoutes),
      },
      {
        path: SELECT_ROLE_PATH,
        loadChildren: () => import('./select-role/select-role.routes').then((m) => m.selectRoleRoutes),
      },
      {
        path: EMAIL_CODE_PATH,
        loadChildren: () => import('./email-code/email-code.routes').then((m) => m.emailCodeRoutes),
      },
    ],
  },
  {
    path: '**',
    redirectTo: LOGIN_PATH,
  },
];

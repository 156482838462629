import {Directive, HostListener} from '@angular/core';
import {IntercomService} from '../intercom.service';

@Directive({
  selector: '[idealsShowChat]'
})
export class ShowChatDirective {
  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event?.preventDefault();
    this._intercomService.showChat();
  }

  constructor(private readonly _intercomService: IntercomService) {}
}

import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';
import localeUk from '@angular/common/locales/uk';
import localeZh from '@angular/common/locales/zh';
import {
  APP_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  InjectionToken,
  LOCALE_ID,
  Provider,
} from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { CULTURE_COOKIE_EXPIRY, Cultures, IStringMap } from '@ideals/types';
import { Utils } from '@ideals/utils';

export const LOCALIZATION_BASE_URL = new InjectionToken<string>('LOCALIZATION_BASE_URL', {
  factory: () => '/',
});

export const BASED_ON_BROWSER_LANGUAGE = new InjectionToken<boolean>('BASED_ON_BROWSER_LANGUAGE', {
  factory: () => false,
});

export class LocalizeTranslateLoader implements TranslateLoader {
  readonly #httpClient = inject(HttpClient);
  readonly #localizationBaseUrl = inject(LOCALIZATION_BASE_URL);

  getTranslation(lang: string): Observable<IStringMap> {
    return this.#httpClient.get<IStringMap>(`${this.#localizationBaseUrl}localization/${lang}.json`).pipe(catchError(() => of({})));
  }
}

export class LocaleId extends String {
  readonly #translateService = inject(TranslateService);

  toString(): string {
    return this.#translateService.currentLang;
  }

  valueOf(): string {
    return this.#translateService.currentLang;
  }
}

export function provideRootTranslate(): (Provider | EnvironmentProviders)[] {
  return [
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: LocalizeTranslateLoader,
        },
      }),
    ),
    {
      provide: LOCALE_ID,
      useClass: LocaleId,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const cookieService = inject(CookieService);
        const translateService = inject(TranslateService);
        const browserLanguage = translateService.getBrowserLang();
        const basedOnBrowserLanguage = inject(BASED_ON_BROWSER_LANGUAGE);

        const getDefaultLanguage = (): string => {
          let language = Cultures.en_US;

          if (browserLanguage) {
            Object.values(Cultures).forEach((lang) => {
              if (lang.includes(browserLanguage)) {
                language = lang;
              }
            });
          }

          if (basedOnBrowserLanguage) {
            cookieService.set('culture', language, new Date(CULTURE_COOKIE_EXPIRY), '/', Utils.domain);
          }

          return language;
        };

        const culture = cookieService.get('culture') || getDefaultLanguage();

        translateService.setDefaultLang(culture);
        translateService.use(culture);

        registerLocaleData(localeDe, Cultures.de_DE);
        registerLocaleData(localeEn, Cultures.en_US);
        registerLocaleData(localeEs, Cultures.es_ES);
        registerLocaleData(localeFr, Cultures.fr_FR);
        registerLocaleData(localeIt, Cultures.it_IT);
        registerLocaleData(localeJa, Cultures.ja_JP);
        registerLocaleData(localeKo, Cultures.ko_KR);
        registerLocaleData(localeNl, Cultures.nl_NL);
        registerLocaleData(localePl, Cultures.pl_PL);
        registerLocaleData(localePt, Cultures.pt_BR);
        registerLocaleData(localeRu, Cultures.ru_RU);
        registerLocaleData(localeSv, Cultures.sv_SE);
        registerLocaleData(localeTr, Cultures.tr_TR);
        registerLocaleData(localeUk, Cultures.uk_UA);
        registerLocaleData(localeZh, Cultures.zh_CN);

        return () => new Promise<void>((resolve) => {
          // https://github.com/ngx-translate/core/issues/1086#issuecomment-508779160
          translateService.onLangChange.pipe(first()).subscribe(() => resolve());
        });
      },
      multi: true,
    },
  ];
}

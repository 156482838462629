const INTERNAL_HOSTS = [
  'account.ideals.localhost',
  'auth.ideals.localhost',
  'app.ideals.localhost',
  'www1.idealsvdr.com',
  'www2.idealsvdr.com',
  'www3.idealsvdr.com',
  'www4.idealsvdr.com',
  'www5.idealsvdr.com',
  'www6.idealsvdr.com',
  'www7.idealsvdr.com',
  'www8.idealsvdr.com',
  'www9.idealsvdr.cn',
  'vdr-qa.stagevdr.com',
  'vdr-uat.stagevdr.com',
  'wuxishare.wuxibiologics.com',
  'wuxishare-app.wuxibiologics.com',
  'wuxishare-auth.wuxibiologics.com',
  'secure.idealsvdr.com',
  'secure-auth.idealsvdr.com',
  'secure-app.idealsvdr.com',
];

export function isInternalUrl(url: string): boolean {
  const env = window.location.hostname.replace(/^auth\.|^app\.|\.com$/g, '');
  const host = new URL(url).hostname;
  const hosts = [
    ...INTERNAL_HOSTS,
    `app.${env}.com`,
    `auth.${env}.com`,
  ];

  return hosts.includes(host);
}

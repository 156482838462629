import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { BrowserService } from '@ideals/services/browser';

import { DESTINATION_URL_PARAM, INCOMPATIBLE_BROWSER_COOKIE, INCOMPATIBLE_BROWSER_PATH } from './types';

export function appGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  const browserService = inject(BrowserService);
  const router = inject(Router);
  const cookieService = inject(CookieService);

  const isAllowed = browserService.isMobile
    || browserService.isBrowserVersionSupported({
      chrome: 109,
      'ms-edge-chromium': 109,
      'ms-edge': 109,
      safari: 16,
      firefox: 116,
      opera: 96,
    })
    || cookieService.check(INCOMPATIBLE_BROWSER_COOKIE);

  if (isAllowed) {
    return true;
  }

  router.navigate([INCOMPATIBLE_BROWSER_PATH], { queryParams: { [DESTINATION_URL_PARAM]: state.url } });

  return false;
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, inject, isDevMode, Provider } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import StackTrace from 'stacktrace-js';

import { BrowserService } from '@ideals/services/browser';
import { APP_CONFIG, LOCATION } from '@ideals/types';

import { EXTERNAL_IDP_PATH, IProfileInfo, REGISTRATION_PATH } from '../../../types';

interface ErrorReport {
  readonly Application: 'auth-frontend';
  readonly Browser: string;
  readonly ClientType: string;
  readonly Exception: string;
  readonly Level: 'error';
  readonly Message: string;
  readonly Os: string;
  readonly Url: string;
  readonly UserEmail?: string;
}

export class CustomErrorHandler implements ErrorHandler {
  readonly #appConfig = inject(APP_CONFIG);
  readonly #browserService = inject(BrowserService);
  readonly #httpClient = inject(HttpClient);
  readonly #location = inject(LOCATION);

  handleError(error: Error | HttpErrorResponse): void {
    const chunkFailedMessage = /Loading chunk [\w-]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      this.#location.reload();
    } else {
      console.error(error);
    }

    if (error instanceof HttpErrorResponse) {
      return;
    }

    if (!isDevMode() && error instanceof Error) {
      this.#getUserEmail()
        .pipe(
          switchMap((email) => {
            return from(StackTrace.fromError(error))
              .pipe(map((frames) => ({ email, frames })));
          }),
          switchMap(({ email, frames }) => {
            const errorReport: ErrorReport = {
              Application: 'auth-frontend',
              Browser: `${this.#browserService.deviceInfo.browser} ${this.#browserService.deviceInfo.browser_version}`,
              ClientType: this.#getClientType(),
              Exception: frames.map((frame) => frame.toString())[0] ?? '',
              Level: 'error',
              Message: error.message,
              Os: `${this.#browserService.deviceInfo.os} ${this.#browserService.deviceInfo.os_version}`,
              Url: this.#location.href,
              UserEmail: email,
            };

            return this.#httpClient.post(`${this.#appConfig.appUrl}/api/v1/log/public`, errorReport);
          })
        )
        .subscribe({
          error: (e) => {
            console.error(e);
          },
        });
    }
  }

  #getClientType(): string {
    if (this.#browserService.isMobile) {
      return this.#browserService.isStandalone ? 'mobile app' : 'mobile';
    }

    return 'desktop';
  }

  #getUserEmail(): Observable<string> {
    if (this.#location.pathname.includes(REGISTRATION_PATH)
        || this.#location.pathname.includes(EXTERNAL_IDP_PATH)
    ) {
      return this.#httpClient.get<IProfileInfo>('/api/registration/profile')
        .pipe(
          map(({ email }) => email),
          catchError(() => of(undefined)),
        );
    }

    return this.#httpClient.get<{ email: string; }>('/api/identity/email')
      .pipe(
        map(({ email }) => email),
        catchError(() => of(undefined)),
      );
  }
}

export function provideErrorHandler(): Provider[] {
  return [
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
  ];
}
